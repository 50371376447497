import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
import { localStorage } from "@inferno/renderer-shared-core";

const log = ILog.logger("AptivadaKeyword.lib");

export const KEYWORD_CONTEST_STORAGE_KEY = "keyword_contest_data";

export interface AptivadaInitProps {
  campaignId: number;
  campaignType: string;
  transparent?: boolean;
  host?: string;
  mobileRedirect?: boolean;
  initialHeight?: number;
  events?: {
    pageLoaded?: () => void;
    userSubmitKeyword?: () => void;
    userLogout?: () => void;
  };
}

export interface AptivadaProps {
  init: (initProps: AptivadaInitProps) => void;
}

export interface StoredContest {
  contestId: string;
  entered?: boolean;
  dismissed?: boolean;
  timestamp?: number;
}

export const getCurrentKeywordContest = (data: Webapi.AptivadaKeywordAppFragment) => {
  const { currentKeyword } = data;
  if (!currentKeyword) {
    return null;
  }
  const { current } = currentKeyword;
  if (current && current.isLive) {
    return current;
  }
  return null;
};

export const getStoredKeywordContests = () => {
  const data = localStorage.getItem(KEYWORD_CONTEST_STORAGE_KEY);
  if (data) {
    try {
      return JSON.parse(data) as StoredContest[];
    } catch (e) {
      log.error("JSON could not parse string from localstorage");
    }
  }
  return [];
};

// check localstorage to see if user entered this contest or dismissed it
export const getStoredKeywordContestById = (id: string) => {
  const contests = getStoredKeywordContests();
  if (contests?.length) {
    return contests.find((details: StoredContest) => details.contestId === id);
  }
  return null;
};

export const putStoredKeywordContest = (details: StoredContest) => {
  const defaultDetails: StoredContest = {
    contestId: "",
    entered: false,
    dismissed: false,
    timestamp: Date.now(),
  };
  const storableContest = { ...defaultDetails, ...details };
  localStorage.setItem(KEYWORD_CONTEST_STORAGE_KEY, JSON.stringify([storableContest]));
};

export const loadAptivadaScripts = (host: string) => {
  const scripts = [`https://${host}/sdk.js`];
  if (typeof document === "undefined") {
    return Promise.reject<void>();
  }
  return Promise.all(
    scripts.map(script => {
      return new Promise<void>(res => {
        const contestScript = document.createElement("script");
        contestScript.src = script;
        contestScript.onload = () => res();
        document.head.append(contestScript);
      });
    }),
  );
};
