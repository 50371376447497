import { ILog } from "@ihr-radioedit/inferno-core";

const log = ILog.logger("LocalStorage");

class LocalStorage {
  readonly hasStorage: boolean = typeof window !== "undefined" && !!window.localStorage;

  clear() {
    if (this.hasStorage) {
      try {
        window.localStorage.clear();
      } catch (e) {
        log.debug(e.message);
      }
    }
  }

  getItem(key: string): string | null {
    if (this.hasStorage) {
      try {
        return window.localStorage.getItem(key);
      } catch (e) {
        log.debug(e.message);
      }
    }
    return null;
  }

  key(index: number): string | null {
    if (this.hasStorage) {
      try {
        return window.localStorage.key(index);
      } catch (e) {
        log.debug(e.message);
      }
    }
    return null;
  }

  removeItem(key: string) {
    if (this.hasStorage) {
      try {
        window.localStorage.removeItem(key);
      } catch (e) {
        log.debug(e.message);
      }
    }
  }

  setItem(key: string, value: string) {
    if (this.hasStorage) {
      try {
        window.localStorage.setItem(key, value);
      } catch (e) {
        log.debug(e.message);
      }
    }
  }
}

export const localStorage = new LocalStorage();
