import { formatDistanceToNow } from "date-fns";
import { inject } from "mobx-react";
import { useEffect, useState } from "react";
import * as React from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import type { Store, ContestEvent } from "@inferno/renderer-shared-core";
import { AppTrayInjector } from "../../chrome/AppTrayInjector";
import { StarIcon } from "../../components/icons/StarIcon.component";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { AptivadaKeywordAppFragment, KeywordDetailsFragment } from "@ihr-radioedit/inferno-webapi";
import {
  AptivadaProps,
  getCurrentKeywordContest,
  getStoredKeywordContestById,
  loadAptivadaScripts,
  putStoredKeywordContest,
  StoredContest,
} from "../../lib/aptivadaKeyword";
import { checkValue } from "../../lib/utilities";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "@inferno/renderer-shared-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { getKeywordContest } from "../../services/Aptivada";
import { Modal } from "../../ui";
import "./AptivadaKeywordContest.style.scss";

declare global {
  interface Window {
    AptivadaAsyncInit: () => void;
    Aptivada: AptivadaProps;
  }
}

const onScrollEffect = (scrolled: (arg: boolean) => void) => {
  if (isWindowDefined()) {
    let timeout = 0;

    const routeEl = document.body.querySelector(".component-routes")!;

    const onScroll = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = window.setTimeout(() => {
        routeEl.removeEventListener("scroll", onScroll);
        scrolled(true);
      }, 200);
    };

    routeEl.addEventListener("scroll", onScroll);

    return function cleanup() {
      if (timeout) {
        window.clearTimeout(timeout);
      }
      routeEl.removeEventListener("scroll", onScroll);
    };
  }
};

const assignLozengeClickHandler = ({
  store,
  callback,
  canSubmit,
  hasEntered,
}: {
  store: Store;
  canSubmit: boolean;
  hasEntered: boolean;
  callback?: () => any;
}) => {
  if (!canSubmit || hasEntered) {
    return () => store.player.togglePlayback(store, { sectionName: "contest_muted" });
  }

  return callback;
};

const assignLozengeCTA = ({
  canSubmit,
  hasEntered,
  timeDistance,
  day,
  time,
  translator,
}: {
  canSubmit: boolean;
  hasEntered: boolean;
  timeDistance: string;
  day: string;
  time: string;
  translator: UseTranslationResponse<"translation">;
}) => {
  const { t } = translator;
  let mutedCTA = t("akc_enter_now");

  if (!canSubmit || hasEntered) {
    if (timeDistance.includes("minute") || timeDistance.includes("hour")) {
      mutedCTA = t("akc_enter_hour", { time });
    } else if (timeDistance === "1 day") {
      mutedCTA = t("akc_enter_day", { time });
    } else {
      mutedCTA = t("akc_enter_day", { day, time });
    }
  }

  return mutedCTA;
};

const AptivadaKeywordModal = ({ open, callback }: { open: boolean; callback?: () => any }) => (
  <Modal open={open} callback={callback} addClass="aptivada-modal">
    <div className="aptivada-campaign" />
  </Modal>
);

const AptivadaKeywordLozenge: React.FunctionComponent<{
  store: Store;
  current: KeywordDetailsFragment;
  next: KeywordDetailsFragment;
  stored?: StoredContest | null;
  callback?: () => any;
}> = ({ store, current, next, stored, callback }) => {
  const { site, env } = store;
  const { isSubmittable } = current;
  const hasEnteredCurrentContest = stored?.entered && stored.contestId === current.countdownKeywordId;
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => onScrollEffect(setHasScrolled), [setHasScrolled]);

  const timezone = checkValue(site.index.timeZone, env.DEFAULT_TIMEZONE);
  const timestamp = checkValue(next?.timeStamp, 0) * 1000;
  const timeDistance = formatDistanceToNow(timestamp);
  const formattedTime = zonedTimeFormat({
    date: timestamp,
    timezone,
    outputFormat: DATETIME_DISPLAY_FORMATS.TWELVE_HOUR_UNPADDED,
  });
  const formattedDay = zonedTimeFormat({
    date: timestamp,
    timezone,
    outputFormat: DATETIME_DISPLAY_FORMATS.DAY_OF_WEEK,
  });
  const onClick = assignLozengeClickHandler({
    store,
    canSubmit: !!isSubmittable,
    hasEntered: !!hasEnteredCurrentContest,
    callback,
  });
  const mutedCTA = assignLozengeCTA({
    canSubmit: !!isSubmittable,
    hasEntered: !!hasEnteredCurrentContest,
    timeDistance,
    time: formattedTime,
    day: formattedDay,
    translator: useTranslation(),
  });

  return (
    <AppTrayInjector>
      <button className="aptivada-lozenge" onClick={onClick} title={mutedCTA}>
        <StarIcon />
        {!hasScrolled ? <span>{mutedCTA}</span> : null}
      </button>
    </AppTrayInjector>
  );
};

interface AptivadaKeywordContestProps {
  keyword: AptivadaKeywordAppFragment;
  store?: Store;
}

interface AptivadaKeywordContestState {
  open: boolean;
  childCampaignId?: number | null;
  currentContest?: Webapi.KeywordDetailsFragment | null;
  muted?: boolean;
}

@inject("store")
export class AptivadaKeywordContest extends React.Component<AptivadaKeywordContestProps, AptivadaKeywordContestState> {
  campaignType = "ihrcountdown";
  contestName = "Keyword Contest";
  mounted = false;
  sdkHost = "";
  timeout = 0;

  constructor(props: AptivadaKeywordContestProps) {
    super(props);

    if (props.store?.env.APTIVADA_SDK_HOST) {
      this.sdkHost = props.store?.env.APTIVADA_SDK_HOST;
    }

    this.state = {
      open: false,
      muted: false,
    };
  }

  async componentDidMount() {
    this.mounted = true;
    const { appId } = this.props.keyword;
    const current = getCurrentKeywordContest(this.props.keyword);

    if (!current?.countdownKeywordId || !appId) {
      return;
    }

    const stored = getStoredKeywordContestById(current?.countdownKeywordId || "");

    // Trigger muted state if current keyword is submittable or user has already entered this window
    let muted = !current.isSubmittable;
    if (stored?.contestId === current.countdownKeywordId && (stored?.entered || stored?.dismissed)) {
      muted = true;
    }

    if (this.mounted) {
      this.setState(
        {
          childCampaignId: parseInt(appId, 10) || null,
          currentContest: current,
          muted,
        },
        () => this.initContest(),
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<AptivadaKeywordContestProps>, _: Readonly<AptivadaKeywordContestState>) {
    if (this.props.keyword !== prevProps.keyword && this.mounted) {
      const current = this.state.currentContest;
      const stored = getStoredKeywordContestById(current?.countdownKeywordId || "");

      let muted = !current?.isSubmittable;
      if (stored?.contestId === current?.countdownKeywordId && (stored?.entered || stored?.dismissed)) {
        muted = true;
      }

      this.initKeywordRefresh(current, muted);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private initKeywordRefresh = (current?: Webapi.KeywordDetailsFragment | null, muted?: boolean) => {
    if (current && muted) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      const endTime = checkValue(current?.endTime, 0) * 1000;
      this.timeout = window.setTimeout(() => {
        this.updateKeyword();
      }, Math.abs(Date.now() - endTime));
    }
  };

  private updateKeyword = async () => {
    const appId = this.state.childCampaignId;

    if (!appId) {
      return;
    }

    const keyword = await getKeywordContest(
      {
        accountId: checkValue(this.props.store?.site.index.slug, ""),
        appId: appId.toString(),
      },
      checkValue(this.props.store?.tags.surrogateKeys, new Set()),
    );

    if (!keyword) {
      return;
    }

    const current = getCurrentKeywordContest(keyword);

    if (!current?.countdownKeywordId) {
      return;
    }

    if (this.mounted) {
      this.setState({
        currentContest: current,
      });
    }
  };

  private handlePageLoaded = () => {
    const { store, keyword } = this.props;
    const { appId } = keyword;

    if (store?.page.currentPage) {
      store.onContestAction.dispatch({
        sectionName: "",
        pageName: store.page.currentPage.name,
        context: this.campaignType,
        action: "contest_open",
        name: this.contestName,
        id: parseInt(appId, 10) || 0,
      } as ContestEvent);
    }

    if (this.mounted) {
      this.setState({ open: true });
    }
  };

  private handleUserCompleteEntry = () => {
    const { store, keyword } = this.props;
    const { appId } = keyword;

    if (store && store.page.currentPage) {
      store.onContestAction.dispatch({
        sectionName: "",
        pageName: store.page.currentPage.name,
        context: this.campaignType,
        action: "contest_exit",
        name: this.contestName,
        id: parseInt(appId, 10) || 0,
        exitType: "entry_success",
      } as ContestEvent);
    }

    if (this.state.currentContest?.countdownKeywordId) {
      const contestId = this.state.currentContest.countdownKeywordId;
      const stored = getStoredKeywordContestById(contestId) || {};
      putStoredKeywordContest({ ...stored, contestId, entered: true });
    }

    if (isWindowDefined()) {
      window.scrollTo(0, 0);
    }
  };

  private handleUserDismiss = () => {
    const { store, keyword } = this.props;
    const { appId } = keyword;
    // tracking
    if (store?.page.currentPage) {
      store.onContestAction.dispatch({
        sectionName: "",
        pageName: store.page.currentPage.name,
        context: this.campaignType,
        action: "contest_exit",
        name: this.contestName,
        id: parseInt(appId, 10) || 0,
        exitType: "dismiss",
      });
    }

    if (this.state.currentContest?.countdownKeywordId) {
      const contestId = this.state.currentContest.countdownKeywordId;
      const stored = getStoredKeywordContestById(contestId) || {};
      putStoredKeywordContest({ ...stored, contestId, dismissed: true });
    }

    if (this.mounted) {
      this.setState({ open: false, muted: true });
    }
  };

  private handleUnmute = () => {
    if (this.mounted) {
      this.setState({ open: true, muted: false }, () => this.initContest());
    }
  };

  private initContest = () => {
    loadAptivadaScripts(this.sdkHost).then(() => {
      if (isWindowDefined()) {
        window.Aptivada?.init({
          campaignId: this.state.childCampaignId || 0,
          campaignType: this.campaignType,
          transparent: true,
          host: this.sdkHost,
          events: {
            pageLoaded: this.handlePageLoaded,
            userSubmitKeyword: this.handleUserCompleteEntry,
            userLogout: () => {
              this.logout();
            },
          },
        });
      }
    });
  };

  private logout = () => {
    if (this.props.store) {
      this.props.store.session.currentSession?.logout();
    }
  };

  render() {
    const { store, keyword } = this.props;
    const { appId } = keyword;

    if (appId && store && this.state.currentContest) {
      const stored = getStoredKeywordContestById(this.state.currentContest.countdownKeywordId || "");

      return this.state.muted ? (
        <AptivadaKeywordLozenge
          store={store}
          current={this.props.keyword.currentKeyword.current}
          next={this.props.keyword.submittableKeyword?.next || this.props.keyword.currentKeyword.next}
          stored={stored}
          callback={this.handleUnmute}
        />
      ) : (
        <AptivadaKeywordModal open={this.state.open} callback={this.handleUserDismiss} />
      );
    }

    return null;
  }
}

export default AptivadaKeywordContest;
